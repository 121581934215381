import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import supabase from '../services/supabase';

export default new Vuex.Store({
  state: {
    systems: [],
    incidents: [],
  },
  getters: {},
  mutations: {
    setSystems: (state, systems) => {
      state.systems = systems;
    },
    updateSystemStatus: (state, { system, status, notify }) => {
      state.systems = state.systems.map((stateSystem) => {
        if (stateSystem.name === system.name) {
          stateSystem.operationalStatus = status;
        }

        return stateSystem;
      });

      (async () => {
        await supabase
          .from('systems')
          .update({ 'operationalStatus': status })
          .eq('name', system.name)
      })();

      if (notify) {
        // Send notification to Discord / Slack
        fetch('https://serene-boba-10747e.netlify.app/.netlify/functions/sendNotification', {
          method: 'POST',
          body: JSON.stringify({
            status,
            systemName: system.name,
          }),
        });
      }
    },
    setIncidents: (state, incidents) => {
      state.incidents = incidents;
    },
    addIncident: (state, { date, fire, time, description }) => {
      (async () => {
        const { data } = await supabase
          .from('incidents')
          .insert([
            { fire, date: `${date}T${time}`, description, isFixed: false, actions: [] },
          ])
          .select('*');

        state.incidents = [...state.incidents, data[0]];
      })();
    },
    addIncidentDetails: (state, { id, date, time, status, description }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === id);
      const actions = state.incidents.find(incident => incident.id === id).actions;

      (async () => {
        const { data } = await supabase
          .from('incidents')
          .update({ actions: [...actions, { description, status, time: new Date(`${date}T${time}`).getTime() }] })
          .eq('id', id)
          .select('*');

        state.incidents.splice(incidentStateIdx, 1, data[0]);
      })();
    },
    updateIncident: (state, { id, date, fire, time, description }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === id);

      (async () => {
        const { data } = await supabase
          .from('incidents')
          .update([
            { fire, description, date: `${date}T${time}` },
          ])
          .eq('id', id)
          .select('*');

        state.incidents.splice(incidentStateIdx, 1, data[0]);
      })();
    },
    updateIncidentDetails: (state, { incidentId, actionId, date, time, status, description }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === incidentId);
      const actions = state.incidents.find(incident => incident.id === incidentId).actions;

      actions[actionId] = {
        description, status, time: new Date(`${date}T${time}`).getTime(),
      };

      (async () => {
        const { data } = await supabase
          .from('incidents')
          .update({ actions })
          .eq('id', incidentId)
          .select('*');

        state.incidents.splice(incidentStateIdx, 1, data[0]);
      })();
    },
    removeIncident: (state, { id }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === id);

      (async () => {
        await supabase
          .from('incidents')
          .delete()
          .eq('id', id);

        state.incidents.splice(incidentStateIdx, 1);
      })();
    },
    removeIncidentAction: (state, { id, actionId }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === id);
      const actions = state.incidents.find(incident => incident.id === id).actions;
      actions.splice(actionId, 1);

      (async () => {
        const { data } = await supabase
          .from('incidents')
          .update({ actions })
          .eq('id', id)
          .select('*');

        state.incidents.splice(incidentStateIdx, 1, data[0]);
      })();
    },
    toggleIncidentStatus: (state, { id, done }) => {
      const incidentStateIdx = state.incidents.findIndex(incident => incident.id === id);

      (async () => {
        const { data } = await supabase
          .from('incidents')
          .update({ isFixed: done })
          .eq('id', id)
          .select('*');

        state.incidents.splice(incidentStateIdx, 1, data[0]);
      })();
    }
  }
});
