import Vue from 'vue';
import App from './App.vue';

import router from "./router";
import store from "./store";

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';

Vue.use(VueCustomTooltip);

import './styles/app.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
