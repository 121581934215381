<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import supabase from './services/supabase.js';

export default {
  name: 'App',
  async mounted() {
    // systems
    let { data: systems } = await supabase.from('systems').select('*');

    this.$store.commit(
      'setSystems',
      systems.sort((a, b) => a.id - b.id)
    );

    // incidents
    let { data: incidents } = await supabase.from('incidents').select('*');

    this.$store.commit(
      'setIncidents',
      incidents.sort((a, b) => a.id - b.id)
    );
  },
};
</script>
